import React from 'react';
import Layout from '../components/layout';
import About from '../components/about/About';

export default function about() {
  return (
      <Layout>
        <About />
      </Layout>
  );
}
